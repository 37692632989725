var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Title ")]),_c('v-card-text',{staticClass:"sync-table"},[_c('v-row',[_c('v-col',{staticClass:"border-right",attrs:{"cols":"4"}},[_c('span',{staticClass:"column-title"},[_vm._v("Our")]),_vm._l((Object.keys(_vm.users.our)),function(item,index){return _c('div',{key:index,staticClass:"d-flex align-center border-bottom",class:{
            'disabled-text':
              _vm.users.resulting[item] &&
              _vm.users.our[item] === _vm.users.resulting[item]
          }},[_c('div',{staticClass:"mx-2 mt-2"},[_c('span',[_vm._v(" "+_vm._s(item)+" ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.users.our[item])+" ")])]),(
              !_vm.users.resulting[item] ||
                _vm.users.our[item] !== _vm.users.resulting[item]
            )?_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":""},on:{"click":function($event){return _vm.addItem(_vm.users.our[item], item)}}},[_c('v-icon',{staticClass:"cursor-pointer"},[_vm._v(" mdi-chevron-right ")])],1):_vm._e()],1)})],2),_c('v-col',{staticClass:"border-right",attrs:{"cols":"4"}},[_c('span',{staticClass:"column-title"},[_vm._v("Result")]),_vm._l((Object.keys(_vm.users.resulting)),function(item,index){return (_vm.users.resulting[item].length)?_c('div',{key:index,staticClass:"d-flex align-center border-bottom"},[_c('div',{staticClass:"mx-2 mt-2"},[_c('span',[_vm._v(" "+_vm._s(item)+" ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.users.resulting[item])+" ")])])]):_vm._e()})],2),_c('v-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"column-title"},[_vm._v("MConect")]),_vm._l((Object.keys(_vm.users.sync)),function(item,index){return _c('div',{key:index,staticClass:"d-flex align-center border-bottom",class:{
            'disabled-text':
              _vm.users.resulting[item] &&
              _vm.users.sync[item] === _vm.users.resulting[item]
          }},[(
              !_vm.users.resulting[item] ||
                _vm.users.sync[item] !== _vm.users.resulting[item]
            )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.addItem(_vm.users.sync[item], item)}}},[_c('v-icon',{staticClass:"cursor-pointer"},[_vm._v(" mdi-chevron-left ")])],1):_vm._e(),_c('div',{staticClass:"mx-2 mt-2"},[_c('span',[_vm._v(" "+_vm._s(item)+" ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.users.sync[item])+" ")])])],1)})],2)],1),_c('div',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.result)+" ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" "+_vm._s(_vm.$t("global_buttons.cancel"))+" ")]),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.confirmSync}},[_vm._v(" "+_vm._s(_vm.$t("global_buttons.confirm"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }