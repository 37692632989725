
import Vue from "vue";

export default Vue.extend({
  name: "SyncData",

  props: {
    value: {
      required: true,
      type: Boolean
    },
    user: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  data: () => ({
    result: {} as any,
    users: {
      our: {
        name: "name_our",
        phone: "+3737999999999"
      },
      resulting: {
        name: "",
        phone: ""
      },
      sync: {
        name: "name_sync",
        phone: "+3737666666666"
      }
    } as any
  }),

  methods: {
    addItem(item: any, key: string) {
      this.users.resulting[key] = item;
      this.$forceUpdate();
    },
    confirmSync() {
      this.result = this.users.resulting;
    }
  }
});
